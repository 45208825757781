import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c('div',{staticStyle:{"position":"absolute","align-self":"end","right":"0"}},[_c(VBtn,{staticClass:"ma-1",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-window-close")])],1)],1),_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Reset Password ")]),_c(VDivider),_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","color":"primary","background-opacity":"0"}}),_c(VCardText,[_c(VForm,{ref:"resetPasswordForm",staticClass:"px-6",attrs:{"lazy-validation":""}},[_c(VTextField,{attrs:{"label":"E-mail","rules":_vm.emailRules},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VBtn,{attrs:{"color":"primary","dark":"","block":"","large":""},on:{"click":_vm.resetPassword,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.resetPassword.apply(null, arguments)}}},[_vm._v(" Reset Password ")])],1)],1),_c(VDivider),_c(VCardText,{attrs:{"align":"center"}},[_c('p',[_vm._v("Enter your email address to reset your password.")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }