<template>
  <v-dialog v-model="getKioskDialog" max-width="40%" persistent>
    <v-card :height="addNewKioskDialogDimension.height">
      <v-card-title class="justify-center">
        Add New Kiosk
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text>
        <v-card flat :height="addNewKioskDialogDimension.height - 200" class="overflow-y-auto">
          <v-form ref="addNewKioskForm" lazy-validation class="px-6">
            <v-text-field v-model="kiosk.name" label="Kiosk's Name" :rules="rules.nameRules" required>
            </v-text-field>
            <v-text-field v-model="kiosk.location" label="Location" :rules="rules.locationRule" required>
            </v-text-field>
            <v-text-field v-model="kiosk.email" label="E-mail" :rules="rules.emailRules" required>
            </v-text-field>
            <v-text-field v-model="kiosk.applicationId" label="ARS Application ID" :rules="rules.nameRules" required>
            </v-text-field>
            <v-select v-model="kiosk.kioskApplicationType" :items="kioskApplicationType" item-text="name" return-object
              label="Select Kiosk Application Type"></v-select>
            <v-select v-model="kiosk.kioskEnv" :items="['Prod', 'Stage', 'Test']"
              label="Select Kiosk Environment"></v-select>
            <v-textarea outlined label="Description" @keyup.enter="addNewKiosk"
              v-model="kiosk.description"></v-textarea>
          </v-form>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text align="center" class="ma-0 pa-0">
        <p class="font-weight-bold pt-2"><v-icon left color="primary">mdi-information-outline</v-icon>Invitation link
          sent
          to the kiosk's email will be use to set the password and activate the kiosk.</p>
      </v-card-text>
      <v-card-text align="right" class="ma-0 pb-0">
        <v-btn color="error" dark large @click="$store.commit('setKioskDialog', false)" width="175" outlined
          class="mx-2">
          <v-icon left>mdi-close</v-icon> Close
        </v-btn>
        <v-btn color="primary" dark large @keyup.enter="addNewKiosk" @click="addNewKiosk" width="175">
          <v-icon left>mdi-plus</v-icon> Invite Kiosk
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'addNewKiosk',
  data() {
    return {
      show: null,
      loading: false,
      kiosk: {
        name: '',
        email: '',
        description: '',
        location: '',
        kioskEnv: '',
        applicationId: '',
        kioskApplicationType: '',
        fdn: window.location.hostname,
      },
      kioskApplicationType: [
        { name: 'Way Finding', value: 'wf' },
        { name: 'Form Filing', value: 'ff' }
      ],
      rules: {
        nameRules: [
          v => !!v || 'Kiosk name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        locationRule: [
          v => !!v || 'Location is required',
        ]
      }
    }
  },
  methods: {
    addNewKiosk() {
      if (this.$refs.addNewKioskForm.validate()) {
        this.loading = true;
        this.$store.dispatch('inviteNewKiosk', this.kiosk)
          .then(response => {
            this.loading = false;
            this.$store.commit('setKioskDialog', false)
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'green'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    }

  },
  computed: {
    ...mapGetters(['getKioskDialog', 'addNewKioskDialogDimension'])
  },
  mounted() {

  }
}
</script>