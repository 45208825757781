import gql from 'graphql-tag'

export const singleAvatarQuery = gql`
query singleAvatarQuery($avatarID: String){
  avatars(where : { avatarId : $avatarID}) {
    id
    avatarId
    avatarName
    avatarImage{
      url
    }
    sitepalId
    gender
  }
}
`

export const avatarQuery = gql`
query avatarQuery {
  avatars {
    id
    avatarId
    avatarName
    avatarImage{
      url
    }
    sitepalId
    gender
  }
}
`

export const langCode = gql`
query languageCodesQuery {
  languageCodes {
    id
    voiceName
    avatarGender
    voiceID
    langID
    engineID
    language
  }
}
`