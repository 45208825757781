import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"35%"},model:{value:(_vm.getCallStatusDialog.show),callback:function ($$v) {_vm.$set(_vm.getCallStatusDialog, "show", $$v)},expression:"getCallStatusDialog.show"}},[_c(VCard,{attrs:{"width":"100%"}},[_c(VCardTitle,{staticClass:"justify-center mb-5"},[_vm._v(_vm._s(_vm.getCallStatusDialog.message))]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"width":"125","color":"error"},on:{"click":function($event){return _vm.closeCallStatusDialog()}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }