import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"55%","persistent":""},model:{value:(_vm.kioskActionConfirmationDialog.show),callback:function ($$v) {_vm.$set(_vm.kioskActionConfirmationDialog, "show", $$v)},expression:"kioskActionConfirmationDialog.show"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.kioskActionConfirmationDialog.header)+" ")]),_c(VDivider),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"mx-2",attrs:{"color":"error","width":"150","outlined":""},on:{"click":function($event){return _vm.$store.commit('setKioskActionConfirmationDialog', { show: false })}}},[_vm._v("Close")]),_c(VBtn,{staticClass:"mx-2",attrs:{"color":"primary","width":"150"},on:{"click":function($event){_vm.confirmKioskActionChange(); _vm.$store.commit('setKioskActionConfirmationDialog', { show: false })}}},[_vm._v(" Confirm ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }