import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"20%","persistent":""},model:{value:(_vm.singleDatePicker),callback:function ($$v) {_vm.singleDatePicker=$$v},expression:"singleDatePicker"}},[_c(VCard,{attrs:{"flat":""}},[_c(VDatePicker,{attrs:{"width":"100%"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c(VCardTitle,{staticClass:"d-flex align-center justify-center"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }