<template>
    <v-dialog width="55%" persistent v-model="kioskActionConfirmationDialog.show">
        <v-card flat>
            <v-card-title class="justify-center">
                {{ kioskActionConfirmationDialog.header }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
                <v-btn color="error" width="150" @click="$store.commit('setKioskActionConfirmationDialog', { show: false })"
                    class="mx-2" outlined>Close</v-btn>
                <v-btn color="primary" width="150"
                    @click="confirmKioskActionChange(); $store.commit('setKioskActionConfirmationDialog', { show: false })"
                    class="mx-2"> Confirm </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "kioskActionConfirmation",
    computed: {
        ...mapGetters(['kioskActionConfirmationDialog'])
    },
    methods: {
        confirmKioskActionChange() {
            if (this.kioskActionConfirmationDialog.action === 'referesh') {
                this.$store.dispatch('autoRefreshKiosk', this.kioskActionConfirmationDialog.kioskId)
            } else {
                this.$store.dispatch('lockKioskApplication', this.kioskActionConfirmationDialog.kioskId)
            }
        }
    }
}
</script>