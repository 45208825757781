<template>
    <v-card flat>
        <v-divider></v-divider>
        <v-card-title class="text-subtitle-1 font-weight-medium py-2 justify-center">DEPARTMENTS</v-card-title>
        <v-select dense outlined :items="departmentItem" class="mx-2" v-model="selectedDepartment"></v-select>
        <v-list dense>
            <v-list-item v-for="(user, i) in getUsers" :key="i">
                <v-row class="pr-3" no-gutters>
                    <v-col cols="10" align-self="center" class="py-0">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon small left
                                    :color="getColor(user.data().onlineStatus)">mdi-checkbox-blank-circle</v-icon>
                                {{ user.data().name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-col>
                    <v-col cols="2">
                        <v-list-item-action>
                            <v-icon color="primary" @click="
                                initiateCall(
                                    user
                                )
                                " class="pl-1">
                                mdi-video
                            </v-icon>
                        </v-list-item-action>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
import { mapGetters } from "vuex";
//   import conferenceCallDialog from "@/components/telepresence/conferenceCallDialog.vue";
import router from "@/router";
export default {
    // components: { conferenceCallDialog },
    name: "orgGroupList",
    data() {
        return {
            category: null,
            userCatgory: ["Online", "Offline", "All"],
            search: "",
            listHeight: 0,
            departmentItem: ["Law Library", "DRO", "JPD"],
            selectedDepartment: "Law Library"
        };
    },
    computed: {
        ...mapGetters([
            "allRegisteredUsers",
            "getWindowDimensions",
            "userGroups",
            "userId",
            "userProfile"
        ]),
        getUsers() {
            let associatedGrp = this.userGroups.filter(grp => grp.name === this.selectedDepartment)
            if(associatedGrp.length != 0){
                return this.allRegisteredUsers.filter(user => user.data().groups.includes(associatedGrp[0].id));
            }else{
                return []
            }
        }
    },
    methods: {
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
        initiateCall(user) {
            if (
                !this.userProfile.data().onCall ||
                router.currentRoute.path === "/liveCall"
            ) {
                this.$store.dispatch("requestCall", {
                    userInfo: user.data(),
                    userId: user.id,
                });
            } else {
                this.$store.commit("setCallStatusDialog", {
                    show: true,
                    message: "Can not initiate a call",
                });
            }
        },
    },
    watch: {
        getWindowDimensions(newVal) {
            this.listHeight = newVal.height / 1.75;
        },
    },
    mounted() {
        this.listHeight = window.innerHeight / 1.75;
    },
}
</script>
<style>
.panelHeader {
    color: blue;
}

.panelContent {
    padding: 0px !important;
    max-width: 100%;
    max-height: 250px;
}

.listContent {
    overflow-y: auto;
    padding: 0px !important;
}
</style>
  