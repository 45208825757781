<template>
    <v-snackbar
            :color="snackBar.color"
            :timeout="snackBar.timeout"
            rounded="pill"
            v-model="snackBar.show">
        <v-icon
                dark
                left
                class="pr-2 mx-auto">
            {{snackBar.icon}}
        </v-icon>
        {{ snackBar.text }}

        <template v-slot:action="{ attrs }">
            <v-btn
                    icon
                    dark
                    v-bind="attrs"
                    @click="snackBar.show = false">
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
      name: 'snackBar',
      computed:{
        ...mapGetters(['snackBar'])
      },

    }
</script>