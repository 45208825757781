import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[_c(VDivider),_c(VCardTitle,{staticClass:"text-subtitle-1 font-weight-medium py-2 justify-center"},[_vm._v("DEPARTMENTS")]),_c(VSelect,{staticClass:"mx-2",attrs:{"dense":"","outlined":"","items":_vm.departmentItem},model:{value:(_vm.selectedDepartment),callback:function ($$v) {_vm.selectedDepartment=$$v},expression:"selectedDepartment"}}),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.getUsers),function(user,i){return _c(VListItem,{key:i},[_c(VRow,{staticClass:"pr-3",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"10","align-self":"center"}},[_c(VListItemContent,[_c(VListItemTitle,[_c(VIcon,{attrs:{"small":"","left":"","color":_vm.getColor(user.data().onlineStatus)}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(user.data().name)+" ")],1)],1)],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VListItemAction,[_c(VIcon,{staticClass:"pl-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.initiateCall(
                                user
                            )}}},[_vm._v(" mdi-video ")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }