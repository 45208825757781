<template>
  <v-dialog max-width="500px" max-height="300px" v-model="getIncomingCallDialog.show" persistent>
    <v-card class="pa-3" height="230px" rounded>
      <p class="text-center font-weight-medium">Receiving A Call From</p>

      <v-row>
        <v-col align="center" cols="12">
          <p class="text-center headline font-weight-light">
            {{ getIncomingCallDialog.meeting.initiatorName }}
          </p>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn class="mx-2" fab dark large color="green" @click="acceptCall(getIncomingCallDialog.meeting)">
            <v-icon dark>mdi-phone</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn class="mx-2" fab dark large color="red" @click="declineCall(getIncomingCallDialog.meeting)">
            <v-icon dark>mdi-phone-hangup</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="pt-3">
        <v-progress-linear indeterminate reverse striped background-opacity="0" color="red darken-2"></v-progress-linear>

        <v-progress-linear indeterminate striped background-opacity="0" color="green"></v-progress-linear>

        <v-progress-linear indeterminate reverse striped background-opacity="0" color="red darken-2"></v-progress-linear>

        <v-progress-linear indeterminate striped background-opacity="0" color="green"></v-progress-linear>
      </div>
    </v-card>
    <audio id="myAudio"></audio>
  </v-dialog>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  name: "incomingCall",
  props: ["caller"],
  data() {
    return {
      dialog: false,
      callerName: "",
      callState: null,
      dialogTimer: null,
      callAccepted: false,
      userProfileCallObj: this.userProfile,
    };
  },
  computed: {
    ...mapGetters([
      "userProfile",
      "getIncomingCallDialog",
      "getIncomingCallCount",
    ]),
  },
  methods: {
    acceptCall(meetInfo) {
      this.$store.dispatch("acceptCallRequest", meetInfo)
    },

    declineCall(meetInfo) {
      this.$store.dispatch("rejectCallRequest", {
        meetInfo: meetInfo,
      });
    },
    closeDialog() {
      clearTimeout(this.dialogTimer);
      this.dialog = false;
    },
  },
  mounted() {
    //
  },
};
</script>
  
<style></style>