<template>
    <v-snackbar color="primary" rounded="pill" v-model="addToCallStatus.show" top class="ml-16 mt-5">
      Calling {{ addToCallStatus.message }}
    </v-snackbar>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'addToCallViewer',
    computed: {
      ...mapGetters(['addToCallStatus'])
    },
    methods: {
        //
    }
  }
  </script>