<template>
    <v-dialog
            max-width="400"
            v-model="show">
        <v-card>
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn icon class="ma-1" @click="$emit('close')">
                    <v-icon  color="red" >mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                Reset Password

            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text>
                <v-form
                        ref="resetPasswordForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="email"
                            label="E-mail"
                            :rules="emailRules">
                    </v-text-field>
                    <v-btn
                            color="primary"
                            dark
                            block
                            large
                            @click="resetPassword"
                            @keyup.enter="resetPassword">
                        Reset Password
                    </v-btn>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text align="center" >
                <p>Enter your email address to reset your password.</p>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
    import {auth} from '@/firebase/index.js';

    export default {
      name: 'resetPassword',
      data(){
        return{
          show:true,
          loading: false,
          email: '',
          emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
        }
      },
      methods:{
        resetPassword(){
          this.loading = true;
          auth.sendPasswordResetEmail(this.email)
            .then(() => {
              this.loading = false;
              this.$store.dispatch('showSnackbar', {
                text: 'Success! Check your email for a reset link',
                color: 'success',
              })
              this.show = false
            }, error => {
              this.loading = false;
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error',
              });
              this.show = false
            })
        }
      }
    }

</script>

