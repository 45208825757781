<template>
    <v-dialog v-model="weekDatePicker" width="25%" persistent>
        <v-card flat>
            <v-card-text class="pa-0 black--text pt-2 pl-2">**Date Range should not extend a week. </v-card-text>
            <v-card-text class="pa-0 black--text py-2 pl-2">**Please select from Monday - Sunday</v-card-text>
            <v-date-picker v-model="dates" range width="100%"></v-date-picker>
            <v-card-title class="d-flex align-center justify-center">
                <v-btn color="error" @click="closeDialog()">Close</v-btn>
            </v-card-title>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'weekDatePicker',
    computed: {
        ...mapGetters(['weekDatePicker', 'selectedWeekDate'])
    },
    data() {
        return {
            dates: []
        }
    },
    methods: {
        closeDialog() {
            this.$store.commit('setcloseWithoutSelection', 1)
            this.$store.commit('setWeekDatePicker', false);
            this.dates = []
        }
    },
    watch: {
        selectedWeekDate(newVal) {
            if (newVal === null) {
                this.dates = []
            }
        },
        dates(newVal, oldVal) {

            if (newVal.length === 2) {
                const date1 = new Date(newVal[0])
                date1.setDate(date1.getDate() + 1)
                const date2 = new Date(newVal[1])
                date2.setDate(date2.getDate() + 1)
                this.$store.commit('setSelectedWeekDate', { startDate: date1, endDate: date2 })
                this.$store.dispatch('getKioskAnalytics', {
                    type: 'Select Week',
                    start: null,
                    end: null
                })
                this.$store.commit('setWeekDatePicker', false)
            }
        }
    }
}
</script>
