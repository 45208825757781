<template>
    <v-snackbar
            transition="slide-x-reverse-transition"
            :timeout="announcement.timeout"
            :color="announcement.color"
            v-model="announcement.show"
            right
            light>
        <div class="pb-2">
            <v-icon left>
                mdi-bullhorn
            </v-icon>
            <span class="subtitle-2">
                Announcement
            </span>
            <v-divider></v-divider>
        </div>
        {{ announcement.text }}
        <template v-slot:action="{ attrs }">
            <v-btn
                    icon
                    v-bind="attrs"
                    @click="announcement.show = false">
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    name: 'announcement',
    computed:{
      ...mapGetters(['announcement'])
    },


  }
</script>