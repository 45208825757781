import * as fb from '../../firebase/index.js'
import router from "../../router/index.js";
import crypto from "crypto"
import axios from 'axios';
import { map } from 'lodash';
export default {
    state: {
        callInitiateListener: undefined,
        callEndTimer: 0,
        addToCallViewer: {}
    },
    getters: {
        getCallInitiateListener: state => state.callInitiateListener,
        getCallEndTimer: state => state.callEndTimer,
        addToCallViewer: state => state.addToCallViewer
    },
    mutations: {
        setAddToCallViewer(state, viewerObj) {
            state.addToCallViewer = viewerObj
        }
    },
    actions: {
        addToExistingCall({ commit, getters, state }, callInfo) {
            commit('setAddToCallViewer', {
                show: true,
                userName: callInfo.userName,
                callId: callInfo.callId
            })
            fb.telepresenceCollection.doc(callInfo.callId).update({
                callStatus: 'addToExisting',
                addToExistingCallUsers: callInfo.userId
            }).then(() => {
                state.callEndTimer = setTimeout(() => {
                    fb.telepresenceCollection.doc(callInfo.callId).update({
                        callStatus: 'addToExistingNotAsnwered',
                        addToExistingCallUsers: null,
                    })
                }, 25000)
            })

        },

        cancelExistingCall({ commit }, callInfo) {
            fb.telepresenceCollection.doc(callInfo.callId).update({
                addToExistingCallUsers: null,
                callStatus: 'addToExistingCancelled'
            })
            commit('setAddToCallViewer', {
                show: false
            })
        },

    }
}