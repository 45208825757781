<template>
    <v-row class="fill-height" justify="center">
        <v-col align="center" align-self="center">
            <h1 class="primary--text"  >Sorry! Page Not Found</h1>
            <h3 class="primary--text"  >(Error 404)</h3>
            <v-img
                    width="50%"
                    contian
                    src="@/assets/notFound.svg">
            </v-img>


        </v-col>
    </v-row>
</template>

<script>
    export default {
      name: 'notFound',
      data(){
        return{
        }
      },
    }
</script>

<style>
    .sun{
        fill: #6053FE !important;
    }
</style>