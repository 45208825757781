<template>
  <v-snackbar color="primary" rounded="pill" v-model="addToCallViewer.show" :timeout="-1" top class="ml-16 mt-5">
    <v-icon dark left class="pr-15 mx-auto">
      mdi-phone
    </v-icon>
    Calling {{ addToCallViewer.userName }}
    <v-icon dark right class="pl-10" color="error" @click="cancelCall()">
      mdi-phone-hangup
    </v-icon>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'addToCallViewer',
  computed: {
    ...mapGetters(['addToCallViewer'])
  },
  methods: {
    cancelCall() {
      this.$store.dispatch('cancelExistingCall', this.addToCallViewer)
    }
  }
}
</script>