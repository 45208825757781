<template>
  <v-app-bar app flat dark color="navBarColor">
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <profileMenu></profileMenu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import profileMenu from "./profileMenu";

export default {
  name: "navBar",
  components: {
    profileMenu,
  },
  computed: {
    ...mapGetters(['isAdmin', 'isSuperAdmin', 'userProfile'])
  },
  methods: {
    showThis() {
      this.$store.dispatch("showAnnouncement", {
        text:
          "Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum " +
          "Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum ",
      });
    },
  },
};
</script>
<style>
.v-toolbar__content {
  padding-left: 0 !important;
}

.cardBorder {
  border-radius: 0 !important;
}
</style>