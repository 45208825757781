<template>
    <v-dialog v-model="fiscalYearPicker" persistent width="30%">
        <v-card>
            <v-card-title>Fiscal Year Analytics</v-card-title>
            <v-card-text>
                The analytics for the fiscal year will be displayed from 1st July to 30th June
            </v-card-text>
            <v-card-text>
                <v-text-field v-model.number="fiscalYear"
                    label="Enter Fiscal Year. Ex To view data from July 2023 to June 2024 enter 2024"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="fetchFiscalYearData()">View</v-btn>
                <V-btn @click="closeDialog()" color="error">Close</V-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'fiscalYearPicker',
    data() {
        return {
            fiscalYear: null
        }
    },
    computed: {
        ...mapGetters(['fiscalYearPicker'])
    },
    methods: {
        closeDialog() {
            this.$store.commit('setFiscalYearPicker', false)
            this.$store.commit('setcloseWithoutSelection', 1)
            // this.$store.dispatch('getKioskAnalytics', {
            //     type: 'Today',
            //     start: null,
            //     end: null
            // })
        },
        fetchFiscalYearData() {
            this.$store.commit("setEnteredFiscalYear", this.fiscalYear - 1)
            let date = new Date()
            date.setDate('01')
            date.setMonth('06')
            date.setFullYear(this.fiscalYear - 1)
            date.setHours(0, 0, 0, 0)

            let date2 = new Date()
            date2.setDate('30')
            date2.setMonth('05')
            date2.setFullYear(this.fiscalYear)
            date2.setHours(0, 0, 0, 0)

            this.$store.commit("setEnteredFiscalYear", { startDate: date, endDate: date2 })
            this.$store.dispatch('getKioskAnalytics', {
                type: 'Fiscal Year',
                start: null,
                end: null
            })
            this.fiscalYear = null
            this.$store.commit('setFiscalYearPicker', false)
        }
    }
}
</script>