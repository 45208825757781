module.exports = {
  firebase: {
    apiKey: "AIzaSyD5CWxGYG0BfRBCmWyLRFABojP57AGavdk",
    authDomain: "north-carolina-661c6.firebaseapp.com",
    projectId: "north-carolina-661c6",
    storageBucket: "north-carolina-661c6.appspot.com",
    messagingSenderId: "1058259501788",
    appId: "1:1058259501788:web:eb8da38858e921aff8aa19",
    measurementId: "G-4Q6Q208BEM"
  },
};
