import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px","max-height":"300px","persistent":""},model:{value:(_vm.getIncomingCallDialog.show),callback:function ($$v) {_vm.$set(_vm.getIncomingCallDialog, "show", $$v)},expression:"getIncomingCallDialog.show"}},[_c(VCard,{staticClass:"pa-3",attrs:{"height":"230px","rounded":""}},[_c('p',{staticClass:"text-center font-weight-medium"},[_vm._v("Receiving A Call From")]),_c(VRow,[_c(VCol,{attrs:{"align":"center","cols":"12"}},[_c('p',{staticClass:"text-center headline font-weight-light"},[_vm._v(" "+_vm._s(_vm.getIncomingCallDialog.meeting.initiatorName)+" ")])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"green"},on:{"click":function($event){return _vm.acceptCall(_vm.getIncomingCallDialog.meeting)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-phone")])],1)],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"red"},on:{"click":function($event){return _vm.declineCall(_vm.getIncomingCallDialog.meeting)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-phone-hangup")])],1)],1)],1),_c('div',{staticClass:"pt-3"},[_c(VProgressLinear,{attrs:{"indeterminate":"","reverse":"","striped":"","background-opacity":"0","color":"red darken-2"}}),_c(VProgressLinear,{attrs:{"indeterminate":"","striped":"","background-opacity":"0","color":"green"}}),_c(VProgressLinear,{attrs:{"indeterminate":"","reverse":"","striped":"","background-opacity":"0","color":"red darken-2"}}),_c(VProgressLinear,{attrs:{"indeterminate":"","striped":"","background-opacity":"0","color":"green"}})],1)],1),_c('audio',{attrs:{"id":"myAudio"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }