<template>
    <v-dialog hide-overlay v-model="avatarConfigViewer.show" max-width="65%">
        <v-card flat width="100%" :height="avatarConfigDimension.outerCardHeight">
            <v-card-title class="justify-center"> Update Avatar </v-card-title>
            <v-divider></v-divider>
            <v-banner single-line color="error" v-if="showBanner" sticky>
                <v-row no-gutters>
                    <v-col cols="1">
                        <v-icon slot="icon" size="36" color="white">
                            mdi-alert-circle
                        </v-icon>
                    </v-col>
                    <v-col cols="10" align-self="center">
                        <span class="white--text font-weight-bold">
                            Please select an avatar to proceed.
                        </span>
                    </v-col>
                    <v-col cols="1">
                        <v-icon size="36" @click="showBanner = false" color="white">
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
            </v-banner>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-card flat>
                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="12" align-self="center" align="center" jutify-center class="mt-2">
                                <span class="font-weight-bold"> Current Avatar on the {{ kioskName }} </span>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="my-2"></v-divider>
                            </v-col>
                            <v-col cols="3">
                                <v-img :src="getCurrentAvatarImage()" contain width="75"></v-img>
                            </v-col>
                            <v-col align-self="center" cols="4">
                                <v-text-field outlined label="Enter Avatar Name" v-model="avatarName" dense
                                    hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                    </v-card>
                    <v-card flat :height="avatarConfigDimension.innerCardHeight" class="overflow-y-auto" :disabled="avatarLoadStatus">
                        <v-row no-gutters>
                            <v-col cols="2" v-for="(avatar, i) in avatars" :key="i">
                                <v-card class="ma-4" @click="selectAvatar(avatar)">
                                    <v-img :src=getImage(avatar.avatarImage.url)></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card flat :height="avatarConfigDimension.innerCardHeight + 160" class="overflow-y-auto">
                        <avatar></avatar>
                    </v-card>
                </v-col>
                <v-col cols="12" align="center" class="mt-2">
                    <v-btn @click="saveSelectedAvatar()" class="ma-4" color="primary" width="175"
                        :disabled="updateDisable">Change Avatar</v-btn>
                    <v-btn @click="$store.commit('closeAvatarConfigViewer'); showBanner = false" class="ma-4" color="error"
                        width="175">Close</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import Avatar from '../../avatar.vue';
export default {
    name: "avatarConfig",
    data() {
        return {
            show: false,
            avatarName: '',
            avatarLink: '',
            kioskName: '',
            selectedAvatar: null,
            showBanner: false,
            innerCardHeight: (window.innerHeight * 48) / 100,
            outerCardHeight: (window.innerHeight * 80) / 100,
            updateDisable: true,
            avatarLoadStatus: true
        }
    },
    components: {
        Avatar
    },
    computed: {
        ...mapGetters(['avatarList', 'avatarConfigViewer', 'avatarConfigDimension', 'getAvatarLoaded']),
        avatars() {
            if (this.avatarConfigViewer.kioskObject !== undefined) {
                let temp = this.avatarList.filter(doc => doc.avatarId === this.avatarConfigViewer.kioskObject.avatarId)
                return this.avatarList
            } else {
                return []
                //return this.avatarList
            }

        }
    },
    watch: {
        getAvatarLoaded(newVal){
            if(newVal){
                this.avatarLoadStatus = false
                this.$store.commit("setAvatarLoaded", false);
            }
        },
        showBanner(newVal) {
            if (newVal) {
                this.$store.commit('setAvatarConfigDimension', {
                    innerCardHeight: this.avatarConfigDimension.innerCardHeight - 53,
                    outerCardHeight: this.avatarConfigDimension.outerCardHeight
                })
            } else {
                this.$store.commit('setAvatarConfigDimension', {
                    innerCardHeight: this.avatarConfigDimension.innerCardHeight + 53,
                    outerCardHeight: this.avatarConfigDimension.outerCardHeight
                })
            }
        },
        avatarConfigViewer(newVal) {
            if (newVal.show) {
                this.$store.dispatch('loadAvatar', {
                    sitepalID: newVal.kioskObject.avatarInfo.sitepalId,
                    avatarID: newVal.kioskObject.avatarInfo.avatarId
                })
                this.avatarName = newVal.kioskObject.avatarInfo.avatarName
                this.kioskName = newVal.kioskObject.name
                this.selectedAvatar = {
                    avatarId: newVal.kioskObject.avatarInfo.avatarId,
                    sitepalId: newVal.kioskObject.avatarInfo.sitepalId
                }
            }
        },
        avatarName(newVal) {
            if (newVal !== this.avatarConfigViewer.kioskObject.avatarInfo.avatarName) {
                this.updateDisable = false
            } else {
                this.updateDisable = true
            }
        }
    },
    methods: {
        getImage(avatar) {
            return 'https://strapi.arsconnect.com' + avatar
        },
        getCurrentAvatarImage() {
            if (this.avatarConfigViewer.kioskObject !== undefined) {
                let temp = this.avatarList.filter(ava => ava.avatarId === this.avatarConfigViewer.kioskObject.avatarInfo.avatarId)
                return 'https://strapi.arsconnect.com' + temp[0].avatarImage.url
            }
        },
        selectAvatar(avatar) {
            this.selectedAvatar = avatar
            this.updateDisable = false
            this.avatarLoadStatus = true
            this.$store.dispatch('loadAvatar', {
                sitepalID: avatar.sitepalId,
                avatarID: avatar.avatarId
            })

        },
        saveSelectedAvatar() {
            let langInfo;
            if (this.selectedAvatar === null) {
                this.showBanner = true
            } else {
                if (this.selectedAvatar.gender === 'female') {
                    langInfo = {
                        en: {
                            langId: 1,
                            engineId: 3,
                            voiceId: 3
                        },
                        es: {
                            langId: 2,
                            engineId: 4,
                            voiceId: 4
                        }
                    }
                } else {
                    langInfo = {
                        en: {
                            langId: 1,
                            engineId: 3,
                            voiceId: 7
                        },
                        es: {
                            langId: 2,
                            engineId: 4,
                            voiceId: 5
                        }
                    }
                }
                let data = {
                    kioskId: this.avatarConfigViewer.kioskObject.avatarInfo.id,
                    sitepalId: this.selectedAvatar.sitepalId,
                    avatarId: this.selectedAvatar.avatarId,
                    avatarName: this.avatarName,
                    langCodes: langInfo
                }
                this.$store.commit('setUpdateAvatarConfirm', {
                    show: true,
                    info: data
                })
            }



        }

    },
    mounted() {

    }

}
</script>