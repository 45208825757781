import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"65%","persistent":""},model:{value:(_vm.selectAvatarViewer),callback:function ($$v) {_vm.selectAvatarViewer=$$v},expression:"selectAvatarViewer"}},[_c(VCard,{attrs:{"flat":"","width":"100%","height":_vm.selectAvatarViewerDimension.outerCardHeight}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Update Avatar ")]),_c(VDivider),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"overflow-y-auto",attrs:{"flat":"","height":_vm.selectAvatarViewerDimension.innerCardHeight}},[_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.avatars),function(avatar,i){return _c(VCol,{key:i,attrs:{"cols":"2"}},[_c(VCard,{staticClass:"ma-4",on:{"click":function($event){return _vm.selectAvatar(avatar)}}},[_c(VImg,{attrs:{"src":_vm.getImage(avatar.avatarImage.url)}})],1)],1)}),1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"overflow-y-auto",attrs:{"flat":"","height":_vm.selectAvatarViewerDimension.innerCardHeight}},[_c(VTextField,{attrs:{"label":"Enter Avatar Name","outlined":"","dense":""},model:{value:(_vm.avatarName),callback:function ($$v) {_vm.avatarName=$$v},expression:"avatarName"}}),_c('avatar')],1)],1),_c(VCol,{staticClass:"mt-2",attrs:{"cols":"12","align":"center"}},[_c(VBtn,{staticClass:"ma-4",attrs:{"color":"primary","width":"175","disabled":_vm.updateDisable},on:{"click":function($event){return _vm.saveSelectedAvatar()}}},[_vm._v("Select Avatar")]),_c(VBtn,{staticClass:"ma-4",attrs:{"color":"error","width":"175"},on:{"click":function($event){return _vm.$store.commit('setSelectAvatarViewer', false)}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }