<template>
    <div class="dashboard">
        <v-fade-transition leave-absolute>
            <router-view></router-view>
        </v-fade-transition>
    </div>
</template>

<script>
export default {
    name: "Dashboard",
};
</script>