<template>
    <v-footer class="main">
        <v-col
                cols="12"
                class="text-center grey--text">
            {{new Date().getFullYear()}} — <strong>ARSconnect - {{ windowSize }} </strong>
        </v-col>
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
      name: 'dasfooter',
      computed:{
        ...mapGetters(['windowSize'])
      }
    }
</script>